define('ember-cli-scroll-to/mixins/link-component', ['exports', 'ember', 'ember-cli-scroll-to/helpers/scroll-to'], function (exports, _ember, _emberCliScrollToHelpersScrollTo) {
  var inject = _ember['default'].inject;
  exports['default'] = _ember['default'].Mixin.create({
    scrollToken: false,
    scrollTo: inject.service(),

    willRender: function willRender() {
      this._super.apply(this, arguments);

      var models = this.get('models', models);
      if (!models.length) {
        return;
      }

      for (var i in models) {
        if ((0, _emberCliScrollToHelpersScrollTo.isToken)(models[i])) {
          this.set('scrollToken', models[i]);
          models.splice(i, 1);
          break;
        }
      }
    },

    _invoke: function _invoke(event) {
      this.get('scrollTo').set('afterTransition', this.get('scrollToken'));
      this._super.apply(this, arguments);
    }
  });
});