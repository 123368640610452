define('ember-cli-scroll-to/mixins/router', ['exports', 'ember'], function (exports, _ember) {
  var on = _ember['default'].on;
  var inject = _ember['default'].inject;

  // TODO: remove this mixin, once the routing service ladnds
  exports['default'] = _ember['default'].Mixin.create({
    scrollTo: inject.service(),

    // HACK: this is so unbelievably ugly that I want to shoot myself in the face
    _doTransition: function _doTransition() {
      var _this = this;

      var transition = this._super.apply(this, arguments);

      transition.then(function (res) {
        if (!('routeName' in res)) {
          // no real transition, link-to points to same route and models
          _this.notifyScrollTo();
        }
      });

      return transition;
    },

    notifyScrollTo: on('didTransition', function () {
      this.get('scrollTo').didTransition();
    })
  });
});