define("ember-composable-helpers/helpers/without", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.without = without;
  function contains(needle, haystack) {
    return Ember.A(haystack).includes(needle);
  }
  function without(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }
    if (Ember.isArray(needle) && needle.length) {
      return haystack.reduce((acc, val) => {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }
    return Ember.A(haystack).without(needle);
  }
  var _default = Ember.Helper.helper(function (_ref) {
    let [needle, haystack] = _ref;
    return without(needle, haystack);
  });
  _exports.default = _default;
});