define("ember-composable-helpers/helpers/map-by", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mapBy = mapBy;
  function mapBy(_ref) {
    let [byPath, array] = _ref;
    if (Ember.isEmpty(byPath)) {
      return [];
    }
    return (0, _asArray.default)(array).map(item => Ember.get(item, byPath));
  }
  var _default = Ember.Helper.helper(mapBy);
  _exports.default = _default;
});