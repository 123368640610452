define('ember-cli-scroll-to/services/scroll-to', ['exports', 'ember'], function (exports, _ember) {
  var $ = _ember['default'].$;
  var schedule = _ember['default'].run.schedule;
  var computed = _ember['default'].computed;

  var DEFAULT_DURATION = 700;
  var DEFAULT_PADDING = 0;

  var $viewport = $('html, body');

  exports['default'] = _ember['default'].Service.extend({
    duration: computed.alias('config.scroll-to.duration'),
    padding: computed.alias('config.scroll-to.padding'),

    afterTransition: null,

    //liquidFireTransitions: inject.service(),

    scrollTo: function scrollTo(target, duration, padding) {
      if (typeof duration !== 'number') {
        duration = this.getWithDefault('duration', DEFAULT_DURATION);
      }
      if (typeof padding !== 'number') {
        padding = this.getWithDefault('padding', DEFAULT_PADDING);
      }

      var scrollTop = undefined;
      if (!target) {
        scrollTop = 0;
        duration = 0;
      } else {
        var offset = $(target).offset();
        scrollTop = offset ? offset.top - padding : 0;
      }

      $viewport.stop(true).animate({ scrollTop: scrollTop }, duration);
    },

    didTransition: function didTransition() {
      var _this = this;

      var token = this.get('afterTransition');
      if (token) {
        this.set('afterTransition', null);
        schedule('afterRender', function () {
          _this.scrollTo(token.target, token.duration, token.padding);
        });
      }
    }
  });
});