define("ember-composable-helpers/helpers/filter-by", ["exports", "ember-composable-helpers/utils/is-equal", "ember-composable-helpers/utils/as-array"], function (_exports, _isEqual, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.filterBy = filterBy;
  function filterBy(_ref) {
    let [byPath, value, array] = _ref;
    if (!Ember.isArray(array) && Ember.isArray(value)) {
      array = value;
      value = undefined;
    }
    array = (0, _asArray.default)(array);
    if (Ember.isEmpty(byPath) || Ember.isEmpty(array)) {
      return [];
    }
    let filterFn;
    if (Ember.isPresent(value)) {
      if (typeof value === 'function') {
        filterFn = item => value(Ember.get(item, byPath));
      } else {
        filterFn = item => (0, _isEqual.default)(Ember.get(item, byPath), value);
      }
    } else {
      filterFn = item => !!Ember.get(item, byPath);
    }
    return array.filter(filterFn);
  }
  var _default = Ember.Helper.helper(filterBy);
  _exports.default = _default;
});