define('ember-cli-scroll-to/initializers/scroll-to', ['exports', 'ember', 'ember-cli-scroll-to/mixins/link-component', 'ember-cli-scroll-to/mixins/router'], function (exports, _ember, _emberCliScrollToMixinsLinkComponent, _emberCliScrollToMixinsRouter) {
  exports.initialize = initialize;

  function initialize() /* application */{
    _ember['default'].LinkComponent.reopen(_emberCliScrollToMixinsLinkComponent['default']);
    _ember['default'].Router.reopen(_emberCliScrollToMixinsRouter['default']);
  }

  exports['default'] = {
    name: 'scroll-to',
    initialize: initialize
  };
});