define("ember-composable-helpers/helpers/map", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.map = map;
  function map(_ref) {
    let [callback, array] = _ref;
    if (Ember.isEmpty(callback)) {
      return [];
    }
    return (0, _asArray.default)(array).map(callback);
  }
  var _default = Ember.Helper.helper(map);
  _exports.default = _default;
});