define("ember-composable-helpers/helpers/keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.keys = keys;
  function keys(_ref) {
    let [object] = _ref;
    if (!object) {
      return object;
    }
    return Object.keys(object);
  }
  var _default = Ember.Helper.helper(keys);
  _exports.default = _default;
});