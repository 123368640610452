define('ember-cli-scroll-to/helpers/scroll-to', ['exports', 'ember'], function (exports, _ember) {
  exports.isToken = isToken;
  exports.scrollTo = scrollTo;
  var TOKEN_SYMBOL = Symbol('Scroll Token');

  exports.TOKEN_SYMBOL = TOKEN_SYMBOL;

  function isToken(token) {
    return token && token[TOKEN_SYMBOL];
  }

  function scrollTo(params /*, hash*/) {
    var token = Object.create(null);
    token[TOKEN_SYMBOL] = true;

    token.target = params[0];
    token.duration = params[1];

    return token;
  }

  exports['default'] = _ember['default'].Helper.helper(scrollTo);
});